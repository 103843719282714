<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="doctorName">
        <el-input placeholder="医生姓名" v-model="searchController.formModel.doctorName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="phoneNumber">
        <el-input placeholder="手机号" v-model="searchController.formModel.phoneNumber" clearable></el-input>
      </el-form-item>
      <el-form-item prop="orgName">
        <el-input placeholder="医生集团" v-model="searchController.formModel.orgName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="hospitalName">
        <el-input placeholder="医疗机构" v-model="searchController.formModel.hospitalName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="departName">
        <el-input placeholder="科室" v-model="searchController.formModel.departName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorTitleCode">
        <FetchSelect
          v-model="searchController.formModel.doctorTitleCode"
          placeholder="职称"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_TITLE' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <el-form-item prop="disabled">
        <el-select placeholder="状态" v-model="searchController.formModel.disabled" clearable>
          <el-option label="有效" :value="0"></el-option>
          <el-option label="无效" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="ifCASign">
        <el-select placeholder="是否签约" v-model="searchController.formModel.ifCASign" clearable>
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="clickAdd">新建</el-button>
      <el-button type="primary" @click="handleRefreshAppInfo" :loading="appInfoController.loading.value">获取app版本信息</el-button>
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />

  <!-- 模态 -->
  <QRCodeModal ref="QRCodeModalRef" />
  <ServicePackageModal ref="ServicePackageModalRef" />
  <CommonWordsModal ref="CommonWordsModalRef" />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import QRCodeModal from '@/components/modal/QRCodeModal/index.vue'
import ServicePackageModal from '@/pages/dr/servicePackage/modal.vue'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import needKeepAliveMixin from '@/mixins/needKeepAliveMixin'
import { actionConfirm } from '@/utils/confirm'
import request from '@/utils/request'
import useRequestData from '@/hooks/useRequestData'
import CommonWordsModal from './commonWords/modal'

const componentName = '/web/system/doctor/doctormgr'

export default {
  name: componentName, // 需要keepalive的组件 一定要有name，用来把name存在vuex中
  setup() {
    const router = useRouter()
    const QRCodeModalRef = ref(null)
    const ServicePackageModalRef = ref(null)
    const CommonWordsModalRef = ref(null)

    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        doctorName: '',
        phoneNumber: '',
        orgName: '',
        hospitalName: '',
        departName: '',
        doctorTitleCode: '',
        disabled: 0,
        ifCASign: '',
      },
    })

    // 获取最新app版本信息
    const appInfoController = useRequestData({
      api: '/web/system/doctor/updateDoctorAppVer',
      manul: true,
    })

    // table
    const columns = [
      {
        label: '医生姓名',
        prop: 'doctorName',
        width: 130,
        // render: (v, record) => {
        //   const width = 48;
        //   const styleStr = `width:${width}px;height:${width}px;`;
        //   return (
        //     <div className="flexCenter">
        //       <div className="headImg_box_1641527843539" style={styleStr}>
        //         <el-image src={record.doctorHeadImg} fit="cover" style={styleStr}></el-image>
        //       </div>
        //       <div>{v}</div>
        //     </div>
        //   );
        // },
      },
      { label: '登录账号', prop: 'loginName', width: 150 },
      { label: '医生集团', prop: 'orgName' },
      { label: '医疗机构', prop: 'hospitalName', width: 210 },
      { label: '科室', prop: 'departName' },
      { label: '职称', prop: 'doctorTitleName', width: 160 },
      { label: '手机号', prop: 'phoneNumber', width: 130 },
      { label: '创建时间', prop: 'createDateStr' },
      { label: '签约时间', prop: 'signDateStr' },
      {
        label: '操作系统',
        prop: '操作系统',
        width: 100,
        render: (_, record) => {
          return record.doctorAppVerDTO?.appVersionCode || ''
        },
      },
      {
        label: 'app版本',
        prop: 'app版本',
        width: 110,
        render: (_, record) => {
          return record.doctorAppVerDTO?.verName || ''
        },
      },
      {
        label: '最后操作时间',
        prop: '最后操作时间',
        render: (_, record) => {
          return record.doctorAppVerDTO?.time || ''
        },
      },
      {
        label: '手机品牌',
        prop: '手机品牌',
        render: (_, record) => {
          return record.doctorAppVerDTO?.deviceBrand || ''
        },
      },
      {
        label: '手机型号',
        prop: '手机型号',
        render: (_, record) => {
          return record.doctorAppVerDTO?.deviceModel || ''
        },
      },
      {
        label: '操作',
        prop: 'actions',
        width: 200,
        fixed: 'right',
        render: (_, record) => {
          const statusText = record.disabled == '0' ? '失效' : '生效'
          return (
            <div>
              <CustomLink
                onClick={() => {
                  clickEdit(record)
                }}
              >
                编辑
              </CustomLink>
              <CustomLink
                onClick={() => {
                  handleToggleStatus(record)
                }}
              >
                {statusText}
              </CustomLink>
              <CustomLink
                onClick={() => {
                  QRCodeModalRef.value.open(record)
                }}
              >
                公众号链接
              </CustomLink>
              <CustomLink
                onClick={() => {
                  handleServicePackage(record)
                }}
              >
                服务包
              </CustomLink>
              <CustomLink
                onClick={() => {
                  CommonWordsModalRef.value?.modalController.open((modalProps) => {
                    modalProps.record = record
                  })
                }}
              >
                常用语
              </CustomLink>
            </div>
          )
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          ...searchController.formModel,
        }
        return request({
          url: '/web/system/doctor/getDoctorList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
      defaultPageSize: 50,
    })

    function clickAdd() {
      router.push('/web/system/doctor/doctormgr/detail')
    }

    function clickEdit(record) {
      router.push(`/web/system/doctor/doctormgr/detail?doctorCode=${record.doctorCode}`)
    }

    function handleToggleStatus(record) {
      const statusText = record.disabled == '0' ? '失效' : '生效'
      actionConfirm(`确认${statusText}？`, () => {
        return request({
          url: '/web/system/doctor/updateDoctorDisabled',
          data: {
            doctorCode: record.doctorCode,
            disabled: record.disabled == '0' ? '1' : '0',
          },
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    function handleServicePackage(record) {
      ServicePackageModalRef.value?.modalController.open((modalProps) => {
        modalProps.record = record
      })
    }

    // 获取医生app版本信息
    async function handleRefreshAppInfo() {
      await appInfoController.getInfo()
      tableController.refresh()
      ElMessage.success('操作成功')
    }

    return {
      QRCodeModalRef,
      ServicePackageModalRef,
      CommonWordsModalRef,
      searchController,
      tableController,
      //
      clickAdd,
      appInfoController,
      handleRefreshAppInfo,
    }
  },
  ...needKeepAliveMixin,
  components: { SearchForm, ProTable, FetchSelect, CustomLink, QRCodeModal, ServicePackageModal, CommonWordsModal },
}
</script>

<style lang="less">
@import url('./index_global.less');
</style>
<style lang="less" scoped></style>
