<template>
  <StyledModal :visible="visible" :title="doctorInfo?.doctorName || ''" :showCancel="false" @close="close" @submit="close" width="1200px">
    <div class="content">
      <div class="url_content">
        <div>
          docotorCode：
          <text class="url_text">{{ doctorInfo?.doctorCode || '' }}</text>
        </div>
        <div>
          H5链接地址：
          <text class="url_text">{{ h5Url }}</text>
        </div>
        <div>
          小程序页面路径：
          <text class="url_text">{{ mpPath }}</text>
        </div>
      </div>

      <div class="code_item_wrap">
        <div class="code_item">
          <QRCode ref="QRCodeRef1" :text="h5Url" :width="280" />
          <div>
            H5二维码
            <a class="save_btn" @click="handleSave(1)">下载</a>
          </div>
        </div>
        <div class="code_item">
          <QRCode ref="QRCodeRef2" :text="jumpmpUrl" :width="280" />
          <div>
            小程序二维码
            <a class="save_btn" @click="handleSave(2)">下载</a>
          </div>
        </div>
        <div v-if="doctorInfo?.doctorWechatImg || doctorInfo?.subscribeQrcodeUrl" class="code_item">
          <img :src="doctorInfo?.doctorWechatImg || doctorInfo?.subscribeQrcodeUrl || ''" style="width: 265px; height: 265px; margin-bottom: 6px; object-fit: contain !important" />
          <div>
            公众号二维码
            <a class="save_btn" @click="handleSave(3)">下载</a>
          </div>
        </div>
      </div>
    </div>
  </StyledModal>
</template>

<script>
import { ref, computed } from 'vue'
import StyledModal from '@/components/modal/StyledModal'
import QRCode from '@/components/QRCode/index.vue'
import { saveImgUrl } from '@/components/QRCode/utils'
import { getIsProd, getHostUrl } from '@/utils/utils'

export default {
  setup() {
    const visible = ref(false)
    const QRCodeRef1 = ref(null)
    const QRCodeRef2 = ref(null)

    const doctorInfo = ref(null)

    // const h5Url = computed(() => `${getHostUrl()}/h5/index.html?doctorCode=${doctorInfo.value?.doctorCode || ''}`)
    const h5Url = computed(() => `https://dr.bld365.com/h5/index.html?doctorCode=${doctorInfo.value?.doctorCode || ''}`)

    // const jumpmpUrl = computed(() => `${getHostUrl()}/jumpmp?doctorCode=${doctorInfo.value?.doctorCode || ''}`)
    const jumpmpUrl = computed(() => `https://dr.bld365.com/jumpmp?doctorCode=${doctorInfo.value?.doctorCode || ''}`)

    const mpPath = computed(() => `pages/index/index?doctorCode=${doctorInfo.value?.doctorCode || ''}`)

    /**
     * @param {object} record
     */
    function open(record) {
      console.log('🚀 ~ record:', record)
      visible.value = true
      doctorInfo.value = record
    }
    function close() {
      visible.value = false
      doctorInfo.value = null
    }
    function handleSave(type) {
      if (type === 1) {
        QRCodeRef1.value?.saveImg(`H5二维码-${doctorInfo.value?.doctorName || ''}`)
      } else if (type === 2) {
        QRCodeRef2.value?.saveImg(`小程序二维码-${doctorInfo.value?.doctorName || ''}`)
      } else if (type === 3) {
        saveImgUrl(`公众号二维码-${doctorInfo.value?.doctorName || ''}`, doctorInfo.value?.doctorWechatImg || doctorInfo.value?.subscribeQrcodeUrl)
      }
    }
    return {
      visible,
      QRCodeRef1,
      QRCodeRef2,
      open,
      close,
      handleSave,
      doctorInfo,
      h5Url,
      jumpmpUrl,
      mpPath,
    }
  },
  components: { StyledModal, QRCode },
}
</script>
<style lang="less" scoped>
@import url('~@/common/styles.less');
.content {
  .url_content {
    line-height: 26px;
    .url_text {
      color: #262626;
    }
  }
  .code_item_wrap {
    .flexCenter;
    justify-content: center;
    .code_item {
      .flexColumn;
      margin: 0 15px;
      .save_btn {
        cursor: pointer;
        color: @primaryColor;
        margin-left: 12px;
      }
    }
  }
}
</style>
