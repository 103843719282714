<template>
  <!-- <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="productTitle">
        <el-input placeholder="标题" v-model="searchController.formModel.productTitle" clearable></el-input>
      </el-form-item>
      <el-form-item prop="disabled">
        <el-select placeholder="状态" v-model="searchController.formModel.disabled" clearable>
          <el-option label="有效" :value="0"></el-option>
          <el-option label="无效" :value="1"></el-option>
        </el-select>
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="clickAdd">新建</el-button>
    </template>
  </SearchForm> -->
  <div style="padding-left: 15px">
    <el-button type="primary" @click="tableController.search" style="margin-right: 0px">查询</el-button>
    <el-button type="primary" @click="clickAdd">新建</el-button>
  </div>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
  <!-- 模态 -->
  <AddModal ref="addModalRef" @successAdd="tableController.search" @successEdit="tableController.refresh" />
</template>
<script>
import { ref } from 'vue'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import CustomLink from '@/components/customStyle/CustomLink/index.vue'
import AddModal from './AddModal.vue'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import request from '@/utils/request'
import { actionConfirm } from '@/utils/confirm'
import { ElMessage } from 'element-plus'

export default {
  props: ['modalProps'],
  setup(props) {
    const { modalProps } = props
    const { record: doctorRecord } = modalProps
    const addModalRef = ref(null)
    // // searchForm
    // const searchController = useSearchFormCtrller({
    //   form: {
    //     productTitle: '',
    //     disabled: '',
    //   },
    // })
    // table
    const columns = [
      { label: '短语内容', prop: 'keywordContent' },
      { label: '排序', prop: 'keywordSeq', width: 80 },
      {
        label: '操作',
        prop: 'actions',
        width: 90,
        fixed: 'right',
        render: (_, record) => {
          const statusText = record.disabled == '0' ? '失效' : '生效'
          return (
            <div>
              <CustomLink
                onClick={() => {
                  clickEdit(record)
                }}
              >
                编辑
              </CustomLink>
              <CustomLink
                onClick={() => {
                  handleDelete(record)
                }}
              >
                删除
              </CustomLink>
            </div>
          )
        },
      },
    ]
    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          doctorCode: doctorRecord?.doctorCode || '',
          // ...searchController.formModel,
        }
        return request({
          url: '/web/system/keyword/getKeywordList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function clickAdd() {
      addModalRef.value.open({
        doctorRecord,
      })
    }

    function clickEdit(record) {
      addModalRef.value.open({
        doctorRecord,
        record,
      })
    }

    function handleDelete(record) {
      actionConfirm(`确认删除？`, () => {
        return request({
          url: '/web/system/keyword/removeKeyword',
          data: {
            keywordCode: record.keywordCode,
          },
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    return {
      addModalRef,
      // searchController, //
      tableController,
      clickAdd,
      clickEdit,
    }
  },
  components: { SearchForm, ProTable, AddModal },
}
</script>
<style lang="less"></style>
