<template>
  <StyledModal :visible="visible" title="医生常用语" @close="close" @submit="submit" :submitLoading="submitLoading">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="100px">
      <el-form-item label="短语内容" prop="keywordContent" :rules="[{ required: true, message: '请输入短语内容' }]">
        <el-input v-model="formModel.form.keywordContent" placeholder="请输入" type="textarea"></el-input>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="keywordSeq"
        :rules="[
          { required: true, message: '请输入排序' },
          { type: 'number', min: 0, max: 99999, message: '请输入0-99999之间的数字' },
        ]"
      >
        <el-input v-model.number="formModel.form.keywordSeq" placeholder="0-99999之间"></el-input>
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal'
import FetchSelect from '@/components/FetchSelect'
import ImgUpload from '@/components/ImgUpload'
import DetailEditor from '@/components/DetailEditor'
import htmlEditor, { setEditorContent } from '@/components/htmlEditor'
import request from '@/utils/request'
import { validateFormRef } from '@/common/utils_element'
import patternCreator from '@/utils/patternCreator'

const defaultForm = {
  keywordContent: '',
  keywordSeq: '',
}

export default {
  data() {
    return { patternCreator }
  },
  emits: ['successAdd', 'successEdit'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)
    const doctorInfo = ref(null)

    const formRef = ref(null)
    const formModel = reactive({
      form: cloneDeep(defaultForm),
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    /**
     * @param {object} params
     * @param {any} params.doctorRecord
     * @param {any} params.record
     */
    function open(params) {
      const { doctorRecord, record: recordParam } = params
      doctorInfo.value = doctorRecord
      if (recordParam) {
        record.v = cloneDeep(recordParam)
        renderForm(cloneDeep(recordParam))
      }

      visible.value = true
    }

    function close() {
      visible.value = false
      record.v = null
      formModel.form = cloneDeep(defaultForm)
    }

    // form回显
    function renderForm(data) {
      formModel.form = {
        ...data,
        productFee: data.productFeeStr ?? 0,
      }
    }

    // 提交
    async function submit() {
      // 验证
      await validateFormRef(formRef)
      // 验证 end

      const postData = {
        doctorCode: doctorInfo.value?.doctorCode,
        ...(isAdd.value ? {} : { keywordCode: record.v.keywordCode }),
        ...formModel.form,
      }
      submitLoading.value = true
      request({
        url: isAdd.value ? '/web/system/keyword/createKeyword' : '/web/system/keyword/updateKeyword',
        data: postData,
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ctx.emit(isAdd.value ? 'successAdd' : 'successEdit')
          close()
        })
    }

    return {
      isAdd,
      visible,
      submitLoading,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, DetailEditor, htmlEditor },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
